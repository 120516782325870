var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "operation-bar",
      style: _vm.$route.name !== "OG-Inspection" ? "padding-top: 49px" : "",
    },
    [
      _vm.$route.name === "OG-Inspection"
        ? _c("ProjectList", {
            on: {
              editProject: _vm.editProject,
              detailProject: _vm.detailProject,
              addProject: _vm.addProjectToItem,
            },
          })
        : _vm._e(),
      _vm.showThirdMenu
        ? _c(
            "div",
            { staticClass: "detail-tabs" },
            _vm._l(_vm.thirdMenu, function (item) {
              return _c(
                "div",
                {
                  key: item.path,
                  class:
                    _vm.$route.name === item.code
                      ? "detail-tabs-item detail-tabs-item--active"
                      : "detail-tabs-item",
                  on: {
                    click: function ($event) {
                      return _vm.changeActive(item)
                    },
                  },
                },
                [
                  _c("p", { staticClass: "detail-tabs-item--top" }, [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src:
                          _vm.$route.name === item.code
                            ? "/img/oilGas/" + item.code + "-active.png"
                            : "/img/oilGas/" + item.code + ".png",
                        alt: "",
                      },
                    }),
                  ]),
                  _c("p", { staticClass: "detail-tabs-item--bottom" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.thirdRouterViewer
        ? _c("router-view", { staticClass: "third-routing" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }