<template>
  <div class="container normal oil-gas-box">
    <operationBar/>
    <CusiumMap class="oil-gas-lastItem"/>
  </div>
</template>

<script>
import CusiumMap from "@/components/cesium/index";
import operationBar from "./operationBar/";

export default {
  name: "OG-Inspection",
  components: {
    CusiumMap,
    operationBar
  },
  data() {
    return {}
  }
};
</script>

<style lang="scss">
.oil-gas-box{
  width: 100%;
  height: 100%;
  display: flex;
  .oil-gas-lastItem{
    flex-grow: 1;
    overflow: hidden;
  }
}
</style>
